import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/mind_engine_hero_bg_original.png';
import React from "react";
import BrainCog_Born from "./BrainCog_Born";


const MindEngine = () => {
    const {t, i18n} = useTranslation(['mind_engine', 'common', 'title']);
    const isChinese = i18n.language === 'zh';
    return (
        <div className={"bg-black"}>
            <SEO title={t('mind_engine', {ns: 'html_title'})} />
            {/*hero*/}
            <div className={"bg-cover bg-black/50 bg-blend-multiply bg-top bg-no-repeat md:min-h-[700px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start sm:max-w-[950px] mt-48 md:mr-48"}>
                    <div className={`text-5xl md:text-[5.5rem] font-bold bg-clip-text text-transparent bg-gradient-to-bl from-red-600 to-blue-500 drop-shadow-2xl leading-normal ${isChinese ? 'tracking-[.05em] sm:tracking-[.2em]' : 'tracking-[.005em] sm:tracking-[.04em]'}`}>{t('hero_heading_1')}</div>
                    <div className={`mt-16 text-3xl font-light font-sans leading-relaxed text-neutral-300 sm:text-neutral-300 sm:text-4xl sm:leading-relaxed ${isChinese ? 'tracking-[.05em] sm:tracking-[.1em]' : 'tracking-[.02em] sm:tracking-[.02em] '}`}>{t('hero_heading_2')}</div>
                </div>
            </div>
            {/*content-container*/}
            <div className={"pb-64 flex flex-col justify-center items-center "}>
                <div className={"border border-blue-900 flex flex-col justify-center items-center max-w-6xl mx-4 px-6 pt-16 pb-16 rounded-3xl bg-cover bg-black/30 bg-blend-multiply bg-center-top bg-no-repeat"}>
{/*                    <div className={"flex justify-center align-items-center space-x-6 mb-4"}>
                        <p className="text-neutral-400 text-xl font-light font-sans leading-loose">{t('p1')}</p>
                    </div>*/}
                    <div className={"font-normal text-neutral-100 text-xl md:text-3xl mb-16 text-center leading-relaxed"}>{t('caption_1')}</div>
                    <img
                        className="md:w-[80%] h-auto"
                        src={process.env.PUBLIC_URL + `/images/mind_engine/structure.png`}
                        alt="structure"
                    />
                </div>
                <BrainCog_Born brainknow={false}/>
            </div>
        </div>
    );
};

export default MindEngine;
