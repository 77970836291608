import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation, Trans} from "react-i18next";

import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtons'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'

const imageCaption = [
  {title: "Neuron 1",
    description_en: "A specific neuron's axonal connections to other neurons. This picture shows connections among different types of neurons by their synapses. Axonal branches and synapses are generated by an algorithm developed in the Neural Computation Group (Contributed by Yi Zeng, Weida Bi, Xuan Tang).",
    description_cn: "特定神经元与其他神经元的轴突连接。此图显示了不同类型神经元之间的突触连接。轴突分支和突触由神经计算组开发的算法生成（由曾毅，毕韦达，唐旋提供）。",
  },
  {title: "Neuron 1",
    description_en: "A shuttle view of the mouse V1 neural network with neurons that are sensitive for orientation selection. The shading of the soma is positive relevant to the fire rate of the specific neuron (Contributed by Yi Zeng, Weida Bi, Xuan Tang, Qingqun Kong).",
    description_cn: "鼠V1神经网络的穿梭视图，其中包含对方向选择敏感的神经元。胞体的阴影与特定神经元的放电率正相关（由曾毅，毕韦达，唐旋，孔庆群提供）。",
  },
  {title: "Neuron 1",
    description_en: "A top view of the mouse V1 neural network with neurons which are sensitive for orientation selection. The shading of the soma is positive relevant to the fire rate of the specific neuron. The yellow somas are firing, while the purple ones are not (Contributed by Yi Zeng, Weida Bi, Xuan Tang, Qingqun Kong).",
    description_cn: "鼠V1神经网络的顶部视图，其中包含对方向选择敏感的神经元。胞体的阴影与特定神经元的放电率正相关。黄色的胞体正在放电，而紫色的则没有（由曾毅，毕韦达，唐旋，孔庆群提供）。",
  },
  {title: "Neuron 1",
    description_en: "Cortical column of a cat's V2 orientation selection neurons. Neurons with the same color are sensitive to the same orientations (Contributed by Yi Zeng, Weida Bi, Xuan Tang, Qingqun Kong).",
    description_cn: "猫V2方向选择神经元的皮层柱。颜色相同的神经元对相同的方向敏感（由曾毅，毕韦达，唐旋，孔庆群提供）。",
  },
  {title: "Neuron 1",
    description_en: "The morphology of the mouse hippocampus. It is composed of several sub regions which are DG, CA1, CA2, CA3. Different types of neurons are the basic components of the hippocampus network (Contributed by Yi Zeng, Weida Bi, Xuan Tang, Qingqun Kong).",
    description_cn: "鼠海马体的形态。它由几个子区域组成，分别是DG、CA1、CA2、CA3。不同类型的神经元是海马网络的基本组成部分（由曾毅，毕韦达，唐旋，孔庆群提供）。",
  },
  {title: "Neuron 1",
    description_en: "CA1 in the CAS Brain knowledge base. This figure shows the structure and relevant knowledge of CA1 in the context of the whole brain structure of the mouse brain. The knowledge on CA1 contains the types and relevant morphology of neurons in CA1, the sub regions of CA1, the number of neurons in CA1, directions of signal transmissions from and to CA1, the cognitive functions and related diseases of CA1. (Contributed by Yi Zeng, Weida Bi, Dongsheng Wang, Xuan Tang).",
    description_cn: "CAS脑知识库中的CA1。此图显示了小鼠大脑整体结构中CA1的结构和相关知识。CA1的知识包括CA1中神经元的类型和相关形态、CA1的子区域、CA1中神经元的数量、从CA1发出和传入CA1的信号传输方向、CA1的认知功能和相关疾病。（由曾毅，毕韦达，王东升，唐旋提供）。",
  },
]


const EmblaCarousel = (props) => {
  const {t, i18n} = useTranslation(['brain_simulation', 'common', 'navbar', 'footer', 'html_title'])
  const isChinese = i18n.language === 'zh';

  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay({ delay: 8000 })])
  const [selectedSlide, setSelectedSlide] = useState(0)

  const logSlidesInView = useCallback((emblaApi) => {
    let currentSlides = emblaApi.slidesInView();
    setSelectedSlide(currentSlides[currentSlides.length - 1]);
  }, [])

  useEffect(() => {
    if (emblaApi) emblaApi.on('slidesInView', logSlidesInView)
  }, [emblaApi, logSlidesInView])

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop

    resetOrStop()
  }, [])

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi, onNavButtonClick)

  return (
      <div className={"flex flex-wrap justify-center items-center lg:space-x-36"}>
        <section className="embla theme-dark max-w-[335px] max-h-[510px]">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {slides.map((index) => (
                  <div className="embla__slide" key={index}>
                    <div className="embla__slide__number">
                      <img
                          src={process.env.PUBLIC_URL + `/images/neuron_carousel/${index + 1}.jpg`}
                          alt={index + 1}
                          className={"w-[330px] border-1 border-green-800"}
                      />
                    </div>
                  </div>
              ))}
            </div>
          </div>
          <div className="embla__controls">
            <div className="embla__buttons">
              <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled}/>
              <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled}/>
            </div>

            <div className="embla__dots">
              {scrollSnaps.map((_, index) => (
                  <DotButton
                      key={index}
                      onClick={() => onDotButtonClick(index)}
                      className={'embla__dot'.concat(
                          index === selectedIndex ? ' embla__dot--selected' : ''
                      )}
                  />
              ))}
            </div>
          </div>
        </section>
        <div className={"md:w-[50%] lg:max-w-[300px] leading-relaxed text-xl font-light text-neutral-300 font-sans mx-12 lg:mx-2 mt-24 lg:mt-0"}>{ isChinese ? imageCaption[selectedSlide]["description_cn"] : imageCaption[selectedSlide]["description_en"]}</div>
      </div>
  )
}

export default EmblaCarousel
