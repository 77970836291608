import React, {lazy, useRef, useState} from 'react';
import {BrowserRouter, Routes, Route, Link, useNavigate} from 'react-router-dom'
import {Helmet, HelmetProvider} from 'react-helmet-async';
import './App.css';
import './styles/Common.css';
import 'animate.css/animate.min.css';

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Services from "./components/Services";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost";
import Structure from "./components/Structure";
import Publications from "./components/Publications";
import MindEngine from "./components/MindEngine";
import BrainSimulation from "./components/BrainSimulation";
import Superalignment from "./components/Superalignment";
// import Chat from "./components/Chat";
import About from "./components/About";
import Bottombar from "./components/Footer";
import ScrollToTopOnMount from './hooks/ScrollToTopOnMount'; // Import the component

const Chat = lazy(() => import("./components/Chat"));

const App = () => {

    const renderNavbarAndBottombar = () => {
        const currentPath = window.location.pathname;

        // Specify the paths where Navbar and Bottombar should not be rendered
        const pathsWithoutNavbarAndBottombar = ['/mind-talk', '/brain-simulation'];

        if (!pathsWithoutNavbarAndBottombar.includes(currentPath)) {
            return (
                <>
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/services" element={<Services/>}/>
                        <Route path="/mind-engine" element={<MindEngine/>}/>
                        <Route path="/superalignment" element={<Superalignment/>}/>
                        <Route path="/blog" element={<Blog/>}/>
                        <Route path="/blog-post" element={<BlogPost/>}/>
                        <Route path="/structure" element={<Structure/>}/>
                        <Route path="/publications" element={<Publications/>}/>
                        <Route path="/about" element={<About/>}/>
                    </Routes>
                    <Bottombar/>
                </>
            );
        }

        // If the current path is in pathsWithoutNavbarAndBottombar, render nothing
        return (
            <>
                {/*<Navbar/>*/}
                <Routes>
                    <Route path="/mind-talk" element={<Chat/>}/>
                    <Route path="/brain-simulation" element={<BrainSimulation/>}/>
                </Routes>
                {/*<Bottombar/>*/}
            </>
        );
    };

    return (
        <HelmetProvider>
            <BrowserRouter basename="/">
                {/*<ScrollToTopOnMount />*/}
                {renderNavbarAndBottombar()}
                <Routes>
                    {/* Additional routes as needed */}
                </Routes>
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App
