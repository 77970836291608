import React from 'react'
import ReactDOM from 'react-dom/client'
import EmblaCarousel from './EmblaCarousel'
import '../styles/base.css'
import '../styles/sandbox.css'
import '../styles/embla.css'

const OPTIONS = { axis: 'y', dragFree: true, loop: true }
const SLIDE_COUNT = 6
const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

const NeuronCarousel = () => (
  <>
    <EmblaCarousel slides={SLIDES} options={OPTIONS} />
  </>
)

export default NeuronCarousel;