import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/superalignment_hero_bg.png';
import seaBackgroundImage from '../assets/images/hero_background/superalignment_sea_bg_original.png';
import React from "react";
import BrainCog_Born from "./BrainCog_Born";


const Superalignment = () => {
    const {t, i18n} = useTranslation(['superalignment', 'home', 'common', 'title']);
    const isChinese = i18n.language === 'zh';
    return (
        <div className={"bg-black"}>
            <SEO title={t('superalignment', {ns: 'html_title'})} />
            {/*hero*/}
            <div className={"bg-cover bg-black/50 bg-blend-multiply bg-top bg-no-repeat md:min-h-[700px] w-full flex flex-col justify-start items-center p-4"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[950px] mt-48 md:mr-48 p-6"}>
                    <div className={`text-5xl md:text-[5.5rem] font-bold tracking-[.005em] bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-tr from-blue-800 to-fuchsia-500 leading-tight ${isChinese ? 'sm:tracking-[.2em]' : 'sm:tracking-[.02em]'}`}>{t('hero_heading_1')}</div>
                    <div className={`mt-16 text-2xl font-light font-sans leading-relaxed text-neutral-300 sm:text-neutral-300 sm:text-4xl sm:leading-relaxed ${isChinese ? 'tracking-[.05em] sm:tracking-[.05em]' : 'tracking-[.02em] sm:tracking-[.02em] '}`}>{t('hero_heading_2')}</div>
                </div>
            </div>
            {/*content-container*/}
            <div className={"pb-64 flex flex-col justify-center items-center "}>
                <div className={"flex flex-col justify-center items-center max-w-6xl mx-4 px-6 pt-0 pb-16 rounded-3xl bg-cover bg-black/30 bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div className={"flex flex-col justify-center align-items-center space-y-6 mb-4"}>
                        <p className="text-neutral-300 text-xl font-light font-sans leading-loose">{t('p1')}{t('p2')}</p>
                    </div>

                    <img src={process.env.PUBLIC_URL + `/images/superalignment/superalignment_robot.jpeg`}
                         alt={"sea"}
                         className={"w-full transition-all mt-5 rounded-3xl"}
                    />

                    <div className={"mt-24"}>
                        <div className={"text-neutral-100 text-2xl sm:text-3xl font-light sm:text-center leading-loose"}>{t('app_title')}</div>
                        <p className="mt-10 text-neutral-300 text-xl font-light font-sans leading-loose">{t('app_description')}</p>
                    </div>

                    {/*sea banner*/}
                    <div className={"bg-cover bg-black/50 bg-blend-multiply bg-top bg-no-repeat w-full flex justify-center items-start flex-wrap sm:space-x-12 p-4 sm:p-8 mt-24 rounded-3xl"}
                         style={{backgroundImage: `url(${seaBackgroundImage})`}}
                    >
                        <img src={process.env.PUBLIC_URL + `/images/services_round/sea.png`}
                             alt={"sea"}
                             className={"h-[150px] group-hover:h-[70px] transition-all duration-500 ease-in-out rounded border-1 border-b-green-800"}
                             loading={"lazy"}
                        />
                        <div className={"max-w-[600px] flex flex-col justify-center items-start "}>
                            <div className={"text-neutral-100 text-2xl font-medium text-center leading-relaxed"}>{t('service_name_sea', {ns: "home"})}</div>
                            <div className={"font-sans text-neutral-300 font-light text-lg mt-4 space-y-2"}>
                                <p>{t('service_intro_sea', {ns: "home"})}</p>
                                <p>{t('service_intro_sea_extend', {ns: "home"})}</p>
                            </div>
                            <a className={"font-sans mt-6 py-2 px-5 bg-white/80 hover:bg-white/70 rounded-full text-blue-950 font-normal text-base text-center sm:tracking-[.05em]"}
                               href={isChinese ? "http://safe-and-ethical.ai/" : "http://safe-and-ethical.ai/en"}>{t('app_button')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default Superalignment;
