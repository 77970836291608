import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_background_original3.jpg';


const About = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title'])
    const isChinese = i18n.language === 'zh';
    return (
        <div className={"bg-black"}>
            <SEO title={t('about', {ns: 'html_title'})}></SEO>
{/*            <div className={"header-container"}>
                <div className={"header"}>
                    <div className={"header-title-1"}>{t('about')} {t('project_name_full', {ns: 'common'})}</div>
                    <div className={"header-title-2"}>{t('title')}</div>
                </div>
            </div>*/}
            <div className={"bg-cover bg-black/60 bg-blend-multiply bg-bottom bg-no-repeat min-h-[650px] sm:min-h-[800px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-64 md:mr-48"}>
                    <div className={` font-bold tracking-[.1em] bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-purple-200/90 to-purple-800/30 leading-normal ${isChinese ? 'text-5xl sm:text-[5.5rem] sm:tracking-[.2em]' : 'text-5xl sm:text-[5.5rem] sm:tracking-[.04em]'}`}>{t('hero_heading_1')}</div>
                    <div className={`mt-16 text-xl font-light font-sans leading-8 text-neutral-400 sm:text-neutral-300 sm:text-4xl sm:leading-relaxed ${isChinese ? 'tracking-[.25em] sm:tracking-[.1em]' : 'tracking-[.02em] sm:tracking-[.02em] '}`}>{t('hero_heading_2')}</div>
                    {/*<div className={`mt-12 text-xl font-medium leading-8 text-neutral-400 sm:text-neutral-400 sm:text-2xl sm:leading-normal ${isChinese ? 'tracking-[.25em] sm:tracking-[.3em]' : 'tracking-[.02em] sm:tracking-[.02em]'}`}>{t('p1')}</div>*/}
                </div>
            </div>
            <div className={"content-container"}>
                <div className={"flex flex-col justify-center items-center max-w-6xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-black/30 bg-blend-multiply bg-center-top bg-no-repeat"}>
                    {/*<h3 className={"font-semibold text-neutral-100 text-4xl mb-14"}>{t('subtitle')}</h3>*/}
                    <div className={"flex justify-center align-items-center space-x-6 mb-4"}>
                        <p className="text-neutral-400 text-xl font-light font-sans leading-loose">{t('p1')}</p>
                        {/*<div className="md:w-[400px]"><img src={"logo/black_transparent_without_text.png"} alt={"about"}/></div>*/}
                    </div>

                    {/*                    <h3>{t('team')}</h3>
                    <p>{t('principal')}：<a href={"http://braincog.ai/~yizeng/"} target={"_blank"}
                                           rel={"noreferrer"}>{t('zeng')}</a><br/>{t('others')}</p>*/}
                </div>
            </div>
        </div>
    );
};

export default About;
