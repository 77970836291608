import {useEffect, useState} from "react";
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import * as contentful from 'contentful';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {BLOCKS, INLINES} from '@contentful/rich-text-types';
import useContentful from "../hooks/use-contentful";
import '../styles/Common.css';

const BlogPost = () => {
    const {t, i18n} = useTranslation(['blog', 'common', 'title'])

    const [data, setData] = useState(null);
    const [errors, setErrors] = useState(null);
    const {REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_CDA_TOKEN} = process.env;

    const client = contentful.createClient({
        space: REACT_APP_CONTENTFUL_SPACE_ID,
        accessToken: REACT_APP_CONTENTFUL_CDA_TOKEN,
    })
    useEffect(() => {
    client
        .getEntry('5Ffob3XoJGrQuKE5uRUugR')
        .then((entry) => {
            console.log(entry);
            setData(entry);console.log("fields: ",entry.fields.content.content);
        })
        .catch((err) => {
            console.log(err);
            setErrors(err);
        })
    }, [])


const RICHTEXT_OPTIONS = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
            [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
            [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
            [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
            [INLINES.HYPERLINK]: (node, children) => (
                <a href={node.data.uri}>{children}</a>
            ),
            [INLINES.EMBEDDED_ENTRY]: (node, children) => {
              // target the contentType of the EMBEDDED_ENTRY to display as you need
              if (node.data.target.sys.contentType.sys.id === "blogPost") {
                return (
                  <a href={`/blog/${node.data.target.fields.slug}`}>            {node.data.target.fields.title}
                  </a>
                );
              }
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {console.log("EMBEDDED_ENTRY_BLOCK1: ",node);
              // target the contentType of the EMBEDDED_ENTRY to display as you need
              if (node.data.target.sys.contentType.sys.id === "codeBlock") {
                return (
                  <pre>
                    <code>{node.data.target.fields.code}</code>
                  </pre>
                );
              }

              if (node.data.target.sys.contentType.sys.id === "videoEmbed") {
                return (
                  <iframe
                    src={node.data.target.fields.embedUrl}
                    height="100%"
                    width="100%"
                    frameBorder="0"
                    scrolling="no"
                    title={node.data.target.fields.title}
                    allowFullScreen={true}
                  />
                );
              }
              if (node.data.target.sys.contentType.sys.id === "componentRichImage") {
                return (
                    <img
                        src={`https://${node.data.target.fields.image.fields.file.url}`}

                    />
                );
              }
            },

            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                console.log("EMBEDDED_ENTRY_BLOCK2: ", node);
                // render the EMBEDDED_ASSET as you need
                return (
                    <img
                        src={`https://${node.data.target.fields.file.url}`}
                        height={node.data.target.fields.file.details.image.height}
                  width={node.data.target.fields.file.details.image.width}
                  alt={node.data.target.fields.description}
                />
              );
            },

            [BLOCKS.EMBEDDED_ENTRY_BLOCK]: (node, children) => {
              // render the EMBEDDED_ASSET as you need
                console.log("EMBEDDED_ENTRY_BLOCK3: ",node);
              return (
                <img
                  src={`https://${node.data.target.fields.file.url}`}
                  height={node.data.target.fields.file.details.image.height}
                  width={node.data.target.fields.file.details.image.width}
                  alt={node.data.target.fields.description}
                />
              );
            },
        },
}

    return (
        <div>
            <Helmet>
                <title>{t('blog', {ns: 'html_title'})}</title>
            </Helmet>
            <div className={"header-container"}>
                <div className={"header"}>
                    <div className={"header-title-1"}>{t('about')} {t('project_name_full', {ns: 'common'})}</div>
                    <div className={"header-title-2"}>{t('subtitle')}</div>
                </div>
            </div>
            <div className={"content-container"}>
                {data && data.fields.content.content &&
                    data.fields.content.content.map((item, index) => (
                    documentToReactComponents(item, RICHTEXT_OPTIONS)
                    ))
                }
                <div className={"content"}>
                    {/*{errors && <div style={{color: "red"}}>{errors.map(error => error.message).join(", ")}</div>}*/}
{/*                    {data && data.pageBlogPostCollection.items.map((item, index) => (
                        <div key={index} id={"blog-post"} className={"blog-post"}>
                            <h2>{item.title}</h2>
                        </div>
                    ))}*/}
                    <h3>{t('about')} {t('project_name_full', {ns: 'common'})}</h3>
                    <p>{t('p1')}</p>
                    <p>{t('p2')}</p>
                    <p>{t('p3')}</p>
                    <p>{t('p4')}</p>

                    <h3>{t('team')}</h3>
                    <p>{t('principal')}：<a href={"http://braincog.ai/~yizeng/"} target={"_blank"}
                                           rel={"noreferrer"}>{t('zeng')}</a><br/>{t('others')}</p>
                </div>
            </div>
        </div>
    );
};

export default BlogPost;
