import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SEO from "./SEO";

function Services() {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';

    const elementsRef = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate__animated', 'animate__fadeIn');
                    } else {
                        entry.target.classList.remove('animate__animated');
                    }
                });
            },
            {threshold: 0}
        );
        elementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });
        return () => {
            elementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    // standalone mind-talk website: http://mind-engine.org/mind-talk/
    const services = [
        {name: "mind_talk", href_cn: '/mind-talk', href_en: '/mind-talk', intro: t("service_intro_mind_talk")},
        // {name: "mind_talk", href_cn: 'http://mind-engine.org/mind-talk/', href_en: 'http://mind-engine.org/mind-talk/', intro: t("service_intro_mind_talk")},
        {name: "mind_mate", href_cn: 'http://mind-engine.org/mind-mate/', href_en: 'http://mind-engine.org/mind-mate/', intro: t("service_intro_mind_mate")},
        {name: "symbiosis_panorama", href_cn: 'http://symbiosis-panorama.ai-for-sdgs.academy/cn/', href_en: 'http://symbiosis-panorama.ai-for-sdgs.academy/', intro: t("service_intro_symbiosis_panorama")},
        {name: "cie", href_cn: 'http://cultural-interactions-engine.ai-for-sdgs.academy/', href_en: 'http://cultural-interactions-engine.ai-for-sdgs.academy/', intro: t("service_intro_cie")},
        {name: "digital_philosophers", href_cn: 'http://cn.digital-philosophers.org/', href_en: 'http://www.digital-philosophers.org/', intro: t("service_intro_digital_philosophers")},
        {name: "sea", href_cn: 'http://safe-and-ethical.ai/', href_en: 'http://safe-and-ethical.ai/en', intro: t("service_intro_sea")},
    ]

    return (
        <div className="">
            {/*<SEO title={t('services', {ns: 'html_title'})}></SEO>*/}
            <div id={"services"} className={"px-5 py-16 relative isolate"}>
{/*                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>*/}
                <div className={"flex justify-center items-center flex-col py-36"}>
                    <div className={`text-5xl md:text-6xl bg-clip-text text-transparent bg-gradient-to-br from-[#ff6995] to-[#73cdff] font-extrabold text-center leading-normal ${isChinese ? 'tracking-[.1em]' : 'tracking-[.02em]'}`}>{t('service_section_title')}</div>
                    <div className={"text-neutral-300 text-xl font-light font-sans leading-relaxed mt-14 max-w-4xl text-center"}>{t('service_section_intro')}</div>
                </div>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4 sm:gap-6 lg:gap-y-32 max-w-7xl mx-auto mb-32">
                    {services.map((item, index) => (
                        <div className={"flex justify-center items-start"}>
                            <a
                                key={item.name}
                                href={isChinese ? item.href_cn : item.href_en}
                                className="relative bg-cover bg-gradient-to-b from-neutral-950/70 to-black bg-blend-multiply bg-bottom bg-no-repeat hover:bg-top group w-full md:w-[320px] md:h-[670px] rounded-lg md:mx-3 px-3 lg:px-5 pt-12 pb-6 mb-6 text-base leading-7 text-gray-900 hover:drop-shadow-md hover:shadow-md transition-all flex flex-col justify-start items-center"
                                rel="noreferrer"
                                ref={el => elementsRef.current[index] = el}
                            >
                                <div className={"flex justify-center items-center rounded-full"}>
                                    <img src={process.env.PUBLIC_URL + `/images/services_round/${item.name}.png`}
                                         alt={item.name}
                                         className={"h-[100px] group-hover:h-[70px] transition-all duration-500 ease-in-out rounded border-1 border-b-green-800"}
                                    />
                                </div>
                                <div
                                    className={"mt-8 text-center font-base text-xl font-sans leading-relaxed transition-all duration-500 ease-in-out text-neutral-300 group-hover:text-white"}>
                                    {t(`service_name_${item.name}`)}
                                </div>
                                <div
                                    className={"mt-8 text-center font-light text-lg font-sans leading-loose transition-all duration-500 ease-in-out text-neutral-400 group-hover:text-neutral-200"}>
                                    {t(`service_intro_${item.name}`)}
                                </div>
                                <div
                                    className={"max-h-0 group-hover:max-w-full group-hover:max-h-full overflow-hidden mt-4 text-center font-light text-lg font-sans leading-loose transition-all duration-500 ease-in-out text-neutral-400 group-hover:text-neutral-200"}>
                                    {t(`service_intro_${item.name}_extend`)}
                                </div>
                                <img src={process.env.PUBLIC_URL + `/images/services_preview/${item.name}_画板 1.png`}
                                     alt={item.name}
                                     className={"absolute bottom-[-20px] right-[-20px] z-20 h-0 lg:h-[600px] group-hover:right-[-80px] lg:group-hover:h-[300px] transition-all duration-500 ease-in-out rounded border-1 border-b-green-800"}
                                />
                            </a>
                        </div>
                    ))}
                </div>

{/*                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>*/}

            </div>

        </div>
    )
}

export default Services