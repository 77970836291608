import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/structure_hero_compressed.jpg';

function Structure() {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';

    const organizations_cn = [
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
    ]

    const organizations_en = [
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
    ]

    const elementsRef = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate__animated', 'animate__fadeIn');
                    } else {
                        entry.target.classList.remove('animate__animated');
                    }
                });
            },
            {threshold: 0}
        );
        elementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });
        return () => {
            elementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, []);

    return (
        <div className={"bg-black"}>
            <SEO title={t('structure', {ns: 'html_title'})}></SEO>
            <div id={"structure"} className={"py-16"}>
                <div className={"flex justify-center items-center flex-col py-36"}>
                    <div className={`animate__animated animate__fadeIn text-5xl md:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-pink-400 to-violet-400 font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.1em]' : 'tracking-[.02em]'}` } >{t('organization_section_title')}</div>
                </div>
                <div className="max-w-[90%] md:w-[70%] md:max-w-[1200px] bg-cover bg-slate-500/60 bg-blend-multiply bg-center-top bg-no-repeat flex justify-center items-center flex-col mx-auto px-4 md:px-20 py-12 md:py-20 rounded-3xl mb-16"
                     style={{backgroundImage: `url(${heroBackgroundImage})`}}
                >
                    <div className={"animate__animated animate__fadeIn text-left text-slate-100 text-3xl leading-normal font-semibold mb-16"}>
                        {t('project_name_short', {ns: 'common'})}
                    </div>

                    {isChinese && organizations_cn.map((item, index) => (
                        <div className={"flex justify-center items-center"}>
                            <a
                                key={item.name}
                                href={item.href}
                                // target={"_blank"}
                                className="max-w-3xl rounded-2xl md:mx-8 px-6 md:px-14 py-8 md:py-12 mb-12 transition-all bg-gradient-to-br from-white/25 to-[#6a1046]/10 hover:shadow-[1px_2px_4px_0px_rgba(255,255,255,0.3)] bg-center bg-300% hover:bg-left"
                                rel="noreferrer"
                                ref={el => elementsRef.current[index] = el}
                            >
                                <div className={"text-left text-neutral-100 text-xl leading-7 font-semibold"}>
                                    {t(`${item.name}`)}
                                </div>
                                <div className={"mt-7 text-left text-neutral-300 text-base leading-loose font-normal"}>
                                    {t(`${item.intro}`)}
                                </div>
                            </a>
                        </div>
                    ))}
                    {!isChinese && organizations_en.map((item, index) => (
                        <div className={"flex justify-center items-center"}>
                            <a
                                key={item.name}
                                href={item.href}
                                // target={"_blank"}
                                className="max-w-3xl rounded-2xl md:mx-8 px-6 md:px-14 py-8 md:py-12 mb-12 transition-all bg-gradient-to-br from-white/25 to-[#6a1046]/10 hover:shadow-[1px_2px_4px_0px_rgba(255,255,255,0.3)] bg-center bg-300% hover:bg-left"
                                rel="noreferrer"
                                ref={el => elementsRef.current[index] = el}
                            >
                                <div className={"text-left text-neutral-100 text-xl leading-7 font-semibold"}>
                                    {t(`${item.name}`)}
                                </div>
                                <div className={"mt-7 text-left text-neutral-300 text-base leading-relaxed font-normal"}>
                                    {t(`${item.intro}`)}
                                </div>
                            </a>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default Structure;