import {useEffect, useState} from "react";
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import useContentful from "../hooks/use-contentful";
import '../styles/Common.css';

const Blog = () => {
    const {t, i18n} = useTranslation(['blog', 'common', 'title'])

    let query = `
        query {
            pageBlogPostCollection {
                items {
                    title
                    content {
                        json
                    }
                }
            }
        }
    `
    let {data, errors} = useContentful(query);
    console.log("data", data)

    return (
        <div>
            <Helmet>
                <title>{t('blog', {ns: 'html_title'})}</title>
            </Helmet>
            <div className={"header-container"}>
                <div className={"header"}>
                    <div className={"header-title-1"}>{t('about')} {t('project_name_full', {ns: 'common'})}</div>
                    <div className={"header-title-2"}>{t('subtitle')}</div>
                </div>
            </div>
            <div className={"content-container"}>
                <div className={"content"}>
                    {errors && <div style={{color: "red"}}>{errors.map(error => error.message).join(", ")}</div>}
                    {data && data.pageBlogPostCollection.items.map((item, index) => (
                        <div key={index} id={"blog-post"} className={"blog-post"}>
                            <h2>{item.title}</h2>
                        </div>
                    ))}
                    <h3>{t('about')} {t('project_name_full', {ns: 'common'})}</h3>
                    <p>{t('p1')}</p>
                    <p>{t('p2')}</p>
                    <p>{t('p3')}</p>
                    <p>{t('p4')}</p>

                    <h3>{t('team')}</h3>
                    <p>{t('principal')}：<a href={"http://braincog.ai/~yizeng/"} target={"_blank"}
                                           rel={"noreferrer"}>{t('zeng')}</a><br/>{t('others')}</p>
                </div>
            </div>
        </div>
    );
};

export default Blog;
