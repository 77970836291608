import {useState, useEffect} from 'react'

const {REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_CDA_TOKEN} = process.env;

const useContentful = (query) => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState(null);
    // pageBlogPostCollection(limit: 1, locale: "${i18n.language}") {

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/master`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${REACT_APP_CONTENTFUL_CDA_TOKEN}`
                },
                body: JSON.stringify({
                    "query": query
                })
            })
            .then(response => response.json())
            .then(({data, errors}) => {
                 if (errors) setErrors(errors)
                 if (data) setData(data)
            })
            .catch(error => setErrors[error]);
    }, [query])

    return {data, errors}
}

export default useContentful